import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/sales/qrf',
        component: () => import('@/layout/SalesLayout.vue'),
        redirect: '/sales/qrf/menunggu-approval',
        children: [
            {
                path: 'menunggu-approval',
                name: 'waiting-approval',
                component: () => import('@/views/ApprovedView.vue'),
            },
            {
                path: 'disetujui-belum-ada-quotation',
                name: 'approved-without-quotation',
                component: () => import('@/views/ApprovedView.vue'),
            },
            {
                path: 'disetujui',
                name: 'approved',
                component: () => import('@/views/ApprovedView.vue'),
            },
            {
                path: 'ditolak',
                name: 'rejected',
                component: () => import('@/views/ApprovedView.vue'),
            },
            {
                path: 'dibatalkan',
                name: 'canceled',
                component: () => import('@/views/ApprovedView.vue'),
            },
            {
                path: 'expired',
                name: 'expired',
                component: () => import('@/views/ApprovedView.vue'),
            },
            {
                path: 'qrf-discount-history',
                name: 'discount-history',
                component: () => import('@/views/ApprovedView.vue'),
            },
            {
                path: 'buat',
                name: 'Buat',
                component: () => import('@/views/CreateApprovedView.vue'),
            },
            {
                path: 'edit/:id',
                name: 'Edit',
                component: () => import('@/views/EditApprovedView.vue'),
            },
            {
                path: 'revisi-masa-berlaku/:id',
                name: 'Revisi-masa-berlaku',
                component: () => import('@/views/RevisionApprovedView.vue'),
            },
            {
                path: 'pengajuan-ulang/:id',
                name: 'Pengajuan-ulang',
                component: () => import('@/views/ResubmitApprovedView.vue'),
            },
            {
                path: 'detail/:id',
                name: 'Detail',
                component: () => import('@/views/ApprovedDetailView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
